
#id65aaff07b0184704f908b0f5 {
  #i0jb {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#cacad0 0%, #cacad0 100%); 
} 
#iu9g {
padding : 10px; 
min-height : 10vh; 
display : flex; 
color : white; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#ihnh {
padding : 10px; 
min-height : 100px; 
width : 177px; 
} 
#i1ng {
padding : 10px; 
min-height : 100px; 
width : 612px; 
color : white; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
} 
#i4c3 {
padding : 10px; 
min-height : 100px; 
width : 242px; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i5arf {
padding : 5px; 
height : auto; 
display : block; 
} 
#iyh4j {
width : auto; 
padding : 0px 5px 0px 5px; 
} 
.link-menu-mediredes {
color : #ffffff; 
font-size : 1.2rem; 
width : auto; 
} 
.link-menu-mediredes:hover  {
color : #ffffff; 
} 
#iion6 {
width : auto; 
padding : 0px 5px 0px 5px; 
} 
#i7fc1 {
padding : 5px; 
height : auto; 
display : block; 
} 
#ibxbl {
width : auto; 
padding : 0px 5px 0px 5px; 
} 
#i4pal {
padding : 5px; 
height : auto; 
display : block; 
} 
#ierip {
width : auto; 
padding : 0px 5px 0px 5px; 
} 
#icfc4 {
padding : 5px; 
height : auto; 
display : block; 
} 
#ikx27 {
width : auto; 
padding : 0px 5px 0px 5px; 
} 
#istlh {
padding : 5px; 
height : auto; 
display : block; 
} 
#i1cux {
min-height : 70vh; 
display : flex; 
flex-wrap : wrap; 
} 
#ipo2l {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
flex-direction : column; 
justify-content : space-around; 
} 
#i45fa {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : flex-end; 
} 
#idln6 {
padding : 10px; 
display : block; 
font-size : 2.5rem; 
color : #ffffff; 
text-align : center; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#iu0wc {
padding : 10px 20px 15px 20px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
cursor : pointer; 
color : #000000; 
__background-type : color; 
width : 150px; 
font-size : 1.4rem; 
box-shadow : 0 0 5px 0 black ; 
} 
#ipyqg {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i8tn9 {
color : black; 
min-height : 169.84375auto; 
width : 556.03125px; 
} 
#iybe1 {
padding : 10px; 
min-height : 100px; 
} 
#ia8u2 {
min-height : 100px; 
} 
#io5hf {
padding : 10px; 
min-height : 100px; 
} 
#i7knd {
padding : 10px; 
min-height : 100px; 
} 
#ikr1s {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#ig85v {
padding : 10px; 
min-height : 100px; 
width : 70%; 
display : flex; 
justify-content : space-around; 
} 
#ih5hi {
padding : 50px 10px 10px 10px; 
min-height : 100px; 
width : 30%; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
} 
#ijcoh {
padding : 10px; 
display : block; 
text-align : center; 
font-size : 2rem; 
font-weight : 700; 
} 
#ionvj {
padding : 10px 20px 15px 20px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
cursor : pointer; 
color : #000000; 
__background-type : color; 
width : 180px; 
font-size : 1.4rem; 
box-shadow : 0 0 5px 0 black ; 
} 
#iu8dz {
padding : 10px; 
justify-content : space-around; 
display : flex; 
align-items : center; 
} 
#itopm {
padding : 10px 20px 15px 20px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
cursor : pointer; 
color : #000000; 
__background-type : color; 
width : 180px; 
font-size : 1.4rem; 
box-shadow : 0 0 5px 0 black ; 
} 
#i8h5k {
padding : 10px; 
justify-content : space-around; 
display : flex; 
align-items : center; 
} 
#ib3va {
color : black; 
width : 85.41%; 
min-height : 10auto; 
} 
#igd7v {
padding : 10px 20px 15px 20px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
cursor : pointer; 
color : #000000; 
__background-type : color; 
width : 180px; 
font-size : 1.4rem; 
box-shadow : 0 0 5px 0 black ; 
} 
#iaxw1 {
padding : 10px; 
justify-content : space-around; 
display : flex; 
align-items : center; 
} 
.item-button-mediredes {
margin : 25px 0px 25px 0px; 
} 
#i65nk {
padding : 10px; 
min-height : 100px; 
} 
#in688 {
padding : 10px; 
display : block; 
font-size : 3rem; 
text-align : center; 
font-weight : 700; 
} 
#iezl1 {
padding : 10px; 
display : block; 
text-align : center; 
font-size : 1.5rem; 
margin : 50px 0 0 0; 
} 
#ilv6t {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
margin : 50px 0px 50px 0px; 
} 
#ihw9z {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3e5f6a 0%, #3e5f6a 100%); 
color : #ffffff; 
} 
#ikirv {
padding : 10px; 
display : block; 
font-weight : 600; 
font-size : 3rem; 
text-align : center; 
} 
#ivbc6 {
padding : 10px; 
min-height : 100px; 
display : block; 
justify-content : space-around; 
align-items : center; 
} 
#igvpy {
padding : 80px 10px 10px 10px; 
min-height : 100px; 
} 
#isxqak {
display : block; 
font-size : 2rem; 
font-weight : 600; 
text-align : center; 
} 
#ibh6vi {
padding : 50px 10px 50px 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#isxwvv {
color : black; 
width : 198px; 
height : 170.5px; 
} 
#i8ouu8 {
color : black; 
width : 194.38671875px; 
height : 167px; 
} 
#i4gt2h {
color : black; 
width : 220.8828125px; 
height : 176px; 
} 
#iwrcfn {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3e5f6a 0%, #3e5f6a 100%); 
color : #ffffff; 
} 
#i2tx9p {
padding : 10px; 
min-height : 100px; 
} 
#idpmak {
padding : 10px; 
display : block; 
} 
#idetlv {
padding : 10px; 
display : block; 
} 
#ildtt5 {
padding : 10px; 
display : block; 
} 
#ilpy86 {
padding : 10px; 
display : block; 
} 
#iqvjiu {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#i0eayi {
height : 350px; 
width : 615px; 
} 
#i1a37f {
color : black; 
} 
#i59fiq {
color : black; 
width : 150px; 
} 
#itac3x {
color : black; 
width : 484px; 
} 
#i590az {
color : #d983a6; 
padding : 5px; 
width : auto; 
height : auto; 
display : flex; 
justify-content : space-around; 
} 
#iq8chx {
padding : 10px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(to top, #09203f 0%, #537895 100%); 
cursor : pointer; 
color : #fff; 
width : 1074px; 
} 
#it2n43 {
color : #d983a6; 
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
.modalStyle {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
} 
#ijbj5k {
border-color : rgba(229,231,235,var(--tw-border-opacity)); 
--tw-border-opacity : 1; 
--tw-shadow : 0 0 #0000; 
--tw-ring-inset : var(--tw-empty, ); 
--tw-ring-offset-width : 0px; 
--tw-ring-offset-color : #fff; 
--tw-ring-color : rgba(59, 130, 246, 0.5); 
--tw-ring-offset-shadow : 0 0 #0000; 
--tw-ring-shadow : 0 0 #0000; 
} 

  @media only screen and (max-width: 992px) {#i1ng {
width : 415px; 
}} 
@media only screen and (max-width: 992px) {#i4c3 {
width : 150px; 
}} 
@media only screen and (max-width: 992px) {#ihnh {
width : 149px; 
}} 

  @media only screen and (max-width: 480px) {#i45fa {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i1ng {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i4c3 {
width : 159px; 
}} 
@media only screen and (max-width: 480px) {#ihnh {
width : 126px; 
}} 
@media only screen and (max-width: 480px) {#ipo2l {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ih5hi {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ig85v {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#in688 {
font-size : 2rem; 
}} 
@media only screen and (max-width: 480px) {#i0eayi {
min-height : 10auto; 
width : 100%; 
height : auto; 
}} 
@media only screen and (max-width: 480px) {#ikirv {
font-size : 2rem; 
}} 
@media only screen and (max-width: 480px) {#ibh6vi {
flex-wrap : wrap; 
}} 

}
  